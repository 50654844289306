<template>
  <div :data-test-id="`cms-${section.name}`" :data-cm-metadata="placementMetadata">
    <component
      :is="hydration ? LazyHydrate : 'div'"
      v-for="{
        id,
        component,
        hydration,
        bottomMargins,
        hideOnBreakpoint,
        isSticky,
        ...content
      } in components"
      :key="id"
      :when="hydration"
    >
      <NuxtErrorBoundary @error="logError($event as Error, component, id)">
        <div
          v-style="{
            display: getVisibilityStyles(hideOnBreakpoint),
            mb: content.type !== 'P13NExperience' && (pageContext || components.length > 1) ? bottomMargins : null,
          }"
          :class="{
            'container': pageContext && !isFullWidth({ component, content }),
            'sticky z-1 top-14 ': isSticky,
          }"
          :data-test-id="`cms-${component}-${id}`"
        >
          <component
            :is="`lazy-cms-${component}`"
            :content="{ id, bottomMargins, ...content }"
            :data-cm-metadata="componentMetadata(id)"
          />
        </div>
      </NuxtErrorBoundary>
    </component>
  </div>
</template>

<script lang="ts" setup>
import { SectionContextKey } from './context'
import mappings from '#content/mappings'
import { getVisibilityStyles } from '#content/utils/getVisibilityStyles'
import LazyHydrate from '#core/components/base/lazy-hydrate/LazyHydrate.vue'
import type { Content } from '#types/content'
import type { HydrationType } from '#types/mappings'
import type { Section } from '#types/page'
import type { Responsive } from '#types/common'
import type { MonetateMappedExperience } from '#types/monetate'
import type { Experience } from '#types/components/cms/experience'

type ContentItem = {
  component: string
  hydration: HydrationType
  isSticky: boolean
  bottomMargins: Record<keyof Responsive, string>
} & Content

const { section, pageContext, lazyMedia } = defineProps<{
  /**
   * CMS Section with content components
   */
  section: Section | any
  /**
   * Use page context in case you need CMS controlled layout to be applied (container) and bottom spacing
   */
  pageContext?: boolean
  /**
   * Lazy load media objects like images and videos inside section
   * Use for elements that would not be visible on the first screen
   */
  lazyMedia?: boolean
}>()

provide(LazyMedia, section.lazy || lazyMedia)
provide(SectionContextKey, !Array.isArray(section) ? section : undefined)

const { isPreview } = useCms()

const { componentSpacingMap } = useAppConfig().components.cms.section
const { breakpoints } = useAppConfig().ds
const { monetateConfig } = useFeatureFlags()
const { sendMonetateEventsToTracking } = useMonetate()

const bps = Object.keys(breakpoints) as (keyof Responsive)[]

const components: ContentItem[] = (section.items || []).reduce((acc, item) => {
  if (!monetateConfig?.isMonetateActive && item.type === 'P13NExperience') return acc

  const config = resolveContentComponent(item, mappings)

  if (!config) return acc

  return [
    ...acc,
    {
      ...config,
      bottomMargins: bps.reduce((acc, bp) => ({
        ...acc,
        [bp]: componentSpacingMap[item.bottomMargins?.[bp] || 'default']
      }), {})
    }
  ]
}, [] as ContentItem[])

const isFullWidth = ({ component, content }) => {
  // segmentation has it's own container to handle full width cases with logic that specific only for it
  return content.isFullWidth || component === 'segmentation' || content.name?.includes('[fullwidth]')
}

const logError = (e: Error, component: string, id: string) => {
  log.error(`${e?.message} | CMS Error: component - ${component}, id - ${id}`, { stackTrace: e.stack })
}

const placementMetadata = isPreview
  ? JSON.stringify([
    { _: `properties.placement-${section.name}` },
    { placementRequest: [{ isInLayout: true, hasItems: true, placementName: section.name }] }
  ])
  : undefined

const componentMetadata = (id: string) => isPreview ? `[{"_":{"$Ref":"content/${id}"}}]` : undefined

const isExperience = (component: Content): component is Experience => component.type === 'P13NExperience'

onMounted(() => {
  if (monetateConfig?.isMonetateActive) {
    const monetateExperiences = components.reduce<MonetateMappedExperience[]>((acc, component) => {
      if (isExperience(component) && component.name !== 'Control') {
        const experienceId = component.experienceId?.split('_').pop()
        const experience = experienceId && window.vfa?.get(experienceId)

        if (experience)
          acc.push(experience)
      }

      return acc
    }, [])

    if (monetateExperiences.length)
      sendMonetateEventsToTracking(monetateExperiences)
  }
})
</script>
